import React, { useState } from "react";
import './App.css';
import { WalletAuth } from 'meritic-credits-sdk/dist/wallet';
import { Collection } from 'meritic-credits-sdk/dist/collection';

function App() {
	
	const [wallet, setWallet] = useState();
	const [user, setUser] = useState();
	
	const setW3AParams = (params) => {
		setUser(params.user);
		if(params.address && params.network){
			var wallet = {address: params.address.map(ad => (ad.address)),  chainId: params.network.chainId.toString(16), status: 'success'};
			console.log(wallet);
			setWallet(wallet);
		}
	}
	
  return (
    <div className="App">
      	<WalletAuth 
			placement={'menu'}
			checkout={false} 
			interact={true}
			orderType={undefined}
			view={{charge: false, balance: false}}
			collectionName={''}
			groupConditions={[]}
			token={undefined}
			contractAddress={''}
			charge={{value: 0, units: 'USDC', chargeFrequency: 'once'}}
			setW3AParams={setW3AParams}
			updateMeritWallet={{tokenId: '', slotId: '', contractAddress: ''}} 
		/> 
		
		{wallet ?
			<Collection
				collectionId={'apppleiedz5'}
				wallet={wallet}
				pools={[]}
				displayBannerImg={true}
			/> : null
		}
			
    </div>
  );
}

export default App;
